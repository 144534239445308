import { useApolloClient } from '@apollo/client'
import gql from 'graphql-tag'

import { MuleSoftCustomerInterestsInput } from '../schema.generated'

const mutation = gql`
  mutation CreateMuleSoftCustomerInterests(
    $interests: MuleSoftCustomerInterestsInput!
  ) {
    createMuleSoftCustomerInterests(interests: $interests)
  }
`

interface Variables {
  interests: MuleSoftCustomerInterestsInput
}

export const useCreateGenericCustomerInterests = () => {
  const apolloClient = useApolloClient()

  return async (interests: MuleSoftCustomerInterestsInput) => {
    await apolloClient.mutate<boolean, Variables>({
      mutation,
      variables: {
        interests,
      },
    })

    return true
  }
}
