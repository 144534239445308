import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { theme } from '@emico/styles'
import {
  FormField as EFormField,
  FlyUpLabel,
  FormFieldLabel,
  FieldError,
  useNonSpammyFieldState,
} from '@emico/ui'

export interface FormFieldProps
  extends React.ComponentProps<typeof EFormField.original> {
  wrapsRadio?: boolean
  disabled?: boolean
  hasError?: boolean
  active?: boolean
}

const StyledFieldError = styled(FieldError.original)`
  position: absolute;
  top: 0;
  right: 0;
  margin-bottom: 1px;
  background: rgba(255, 255, 255, 0.8);
  color: ${theme.errorColor};
  font-size: 13px;
  transform: translate3d(0, -105%, 0);
`
FieldError.override(StyledFieldError as typeof FieldError.original)

const StyledFormField = styled(EFormField.original)<FormFieldProps>`
  position: relative;
  box-sizing: border-box;
  padding: 10px 12px 10px;
  margin: 10px 0 20px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 #f3efeb, 0 2px 3px 0 #f3efeb, 0 1px 2px 0 #f3efeb;

  ${(props) =>
    props.wrapsRadio &&
    css`
      position: initial;

      ${StyledFieldError} {
        top: 10px;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: #f0f0f0;
      box-shadow: none;
    `}

    ${(props) =>
    props.hasError &&
    css`
      border-color: ${theme.errorColor};
    `}

    ${(props) =>
    props.success &&
    css`
      border-color: ${theme.greenColor};
    `}
`

FlyUpLabel.override(
  styled(FlyUpLabel.original)`
    position: absolute;
    top: 1em;
    left: 12px;
    margin: 0 1px;
    font-weight: 500;
    font-size: 16px;
    pointer-events: none;
    transform-origin: top left;
    transition: transform 0.2s ease;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    *:focus + & {
      transform: scale(0.625) translateY(-20px);
    }

    ${({ hasFocus }) =>
      hasFocus &&
      css`
        transform: scale(0.625) translateY(-20px);
      `}
  ` as typeof FlyUpLabel.original,
)

FormFieldLabel.override(
  styled(FormFieldLabel.original)`
    display: block;
    font-size: 10px;
    line-height: 1;
  ` as typeof FormFieldLabel.original,
)

export const FormField = ({
  success,
  field,
  wrapsRadio,
  disabled,
  ...props
}: FormFieldProps) => {
  const fieldState = useNonSpammyFieldState(field)
  const isValid =
    !wrapsRadio && Boolean(fieldState.value) && fieldState.error === ''

  return (
    <StyledFormField
      disabled={disabled}
      wrapsRadio={wrapsRadio}
      success={success || isValid}
      hasError={Boolean(fieldState.error) && fieldState.error !== ''}
      field={field}
      {...props}
    />
  )
}
