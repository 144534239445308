import styled from '@emotion/styled'
import theme from '@src/theme'
import React, { ReactNode } from 'react'

import { ButtonPrimary } from '@emico/ui'

type SubmitButtonProps = {
  analyticsContext: string
  analyticsName: string
  disabled?: boolean
  children: ReactNode
  loading?: boolean
}

const StyledButton = styled(ButtonPrimary)`
  padding: ${theme.spacing.x1}px;
  border-radius: 4px;
  background-color: ${theme.colorGreenPrimary};
  font-family: ${theme.fontFamilySecondary};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.medium};

  &:hover {
    background: ${theme.colorGreenPrimary};
  }

  &:disabled {
    background: ${theme.gray500};
  }
`

export const SubmitButton = ({ children, ...rest }: SubmitButtonProps) => (
  <StyledButton type="submit" {...rest}>
    {children}
  </StyledButton>
)
