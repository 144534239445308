import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Fieldset,
  FormControl,
  FormInner,
  SubmitButton,
} from '@generic-lead-form/form'
import { Checkbox, Input } from '@generic-lead-form/form-elements'
import { DisplayModes } from '@generic-lead-form/GenericLeadForm'
import { BackgroundImage, Message, message, Title } from '@generic-lead-form/ui'
import { Trans } from '@lingui/macro'
import { HoneypotForm } from '@src/components/HoneypotForm'
import theme from '@src/theme'
import { validateRequired, validateEmail } from '@src/utils/formValidation'
import { FormValues } from 'informed'
import React, { ReactElement } from 'react'

import { globalWindow } from '@emico/ssr-utils'

export type DefaultFormProps = {
  displayMode: DisplayModes
  labels: {
    firstName: string
    lastName: string
    email: string
    postcode: string
    houseNumber: string
    privacy: string | ReactElement
    winPrivacy: string | ReactElement
  }
  loading: boolean
  message: message
  onSubmit: (values: FormValues) => void
}

const FormFooter = styled.footer`
  padding-top: ${theme.spacing.x1}px;
`

export const DefaultForm = ({
  displayMode,
  labels,
  loading,
  message,
  onSubmit,
}: DefaultFormProps) => (
  <>
    <BackgroundImage
      alt=""
      height={425}
      url={`${globalWindow?.location.origin}/media/crm-form/generic-lead-form.png`}
      width={295}
    />

    <HoneypotForm
      onSubmit={onSubmit}
      render={(formState) => (
        <FormInner>
          {displayMode === DisplayModes.form ? (
            <>
              <Title>
                <Trans>What is your email address?</Trans>
              </Title>

              <Fieldset direction="column">
                <FormControl
                  field="firstName"
                  label={labels.firstName}
                  required
                >
                  <Input
                    autoComplete="given-name"
                    data-testid="genericLeadForm.defaultForm.firstName"
                    field="firstName"
                    type="text"
                    validate={validateRequired()}
                    validateOnBlur
                    validateOnChange
                  />
                </FormControl>

                <FormControl field="lastName" label={labels.lastName} required>
                  <Input
                    autoComplete="family-name"
                    data-testid="genericLeadForm.defaultForm.lastName"
                    field="lastName"
                    type="text"
                    validate={validateRequired()}
                    validateOnBlur
                    validateOnChange
                  />
                </FormControl>
              </Fieldset>

              <Fieldset>
                <FormControl field="email" label={labels.email} required>
                  <Input
                    autoComplete="email"
                    data-testid="genericLeadForm.defaultForm.email"
                    field="email"
                    type="email"
                    validate={validateEmail()}
                    validateOnBlur
                    validateOnChange
                  />
                </FormControl>
              </Fieldset>

              <Fieldset>
                <FormControl field="address.postalCode" label={labels.postcode}>
                  <Input
                    autoComplete="postal-code"
                    data-testid="genericLeadForm.defaultForm.postcode"
                    field="address.postalCode"
                    type="text"
                  />
                </FormControl>

                <FormControl
                  field="address.houseNumber"
                  label={labels.houseNumber}
                >
                  <Input
                    autoComplete="address-line1"
                    data-testid="genericLeadForm.defaultForm.houseNumber"
                    field="address.houseNumber"
                    type="text"
                  />
                </FormControl>
              </Fieldset>

              <Fieldset>
                <Checkbox
                  field="privacy"
                  label={labels.privacy}
                  data-testid="genericLeadForm.defaultForm.privacy"
                />
              </Fieldset>

              <FormFooter>
                <SubmitButton
                  analyticsContext="genericLeadForm.defaultForm"
                  analyticsName="submit"
                  css={css`
                    width: 100%;
                    min-height: 55px;
                    font-size: ${theme.fontSizes.xLarge};
                  `}
                  disabled={!formState?.values?.privacy}
                  loading={loading}
                >
                  <Trans>Keep me posted!</Trans>
                </SubmitButton>
              </FormFooter>
            </>
          ) : (
            <Message
              css={css`
                padding-top: 152px;
                padding-bottom: 152px;
              `}
              displayMode={displayMode}
              message={message}
            />
          )}
        </FormInner>
      )}
    />
  </>
)
