import styled from '@emotion/styled'
import { FormField } from '@src/components/FormField'
import theme from '@src/theme'
import React, { ReactNode } from 'react'

import { FormFieldLabel, useNonSpammyFieldState } from '@emico/ui'

type FormControlProps = {
  children: ReactNode
  field: string
  hasError?: boolean
  gap?: number
  label: string
  required?: boolean
}

const StyledFieldLabel = styled(FormFieldLabel.original)`
  font-family: ${theme.fontFamilySecondary};
  font-size: ${theme.fontSizes.xSmall};
`

FormFieldLabel.override(StyledFieldLabel as typeof FormFieldLabel.original)

const StyledFormControl = styled(FormField)<FormControlProps>`
  flex-grow: 1;
  padding: 2px 8px 5px;
  border: 1px solid ${theme.colorGeyser};
  box-shadow: none;

  ${(props) => `
    margin: 0 ${theme.spacing.x1}px ${props.gap}px;
  `}

  ${(props) =>
    props.required &&
    `
      ${StyledFieldLabel} {
        &:after {
          position: absolute;
          margin-left: ${theme.spacing.x2}px;
          color: ${theme.errorColor};
          font-size: ${theme.fontSizes.xLarge};
          line-height: 1.4;
          content: '*';
        }
      }
    `}

  ${(props) =>
    props.hasError &&
    `
      margin-bottom: ${theme.spacing.x5}px;
      border-color: ${theme.errorColor};

      [role='alert'] {
        position: absolute;
        left: 0;
        margin-top: ${theme.spacing.x1}px;
        font-size: ${theme.fontSizes.xSmall};
      }
    `}
`

export const FormControl = ({
  children,
  field,
  gap = theme.spacing.x3,
  label,
  required = false,
}: FormControlProps) => {
  const fieldState = useNonSpammyFieldState(field)

  return (
    <StyledFormControl
      field={field}
      gap={gap}
      hasError={Boolean(fieldState.error)}
      label={label}
      required={required}
    >
      {children}
    </StyledFormControl>
  )
}
