import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

type StepProps = {
  index: number
  render: (isActive: boolean) => ReactNode
  step: number
}

type StyledStepProps = {
  active: boolean
}

const StyledStep = styled.div<StyledStepProps>`
  display: ${({ active }) => (active ? 'block' : 'none')};
`

export const Step = ({ index, render, step }: StepProps) => {
  const isActive = index === step

  return <StyledStep active={isActive}>{render(isActive)}</StyledStep>
}
