import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { HoneypotForm } from '@src/components/HoneypotForm'
import theme from '@src/theme'
import { validateRequired, validateEmail } from '@src/utils/formValidation'
import React from 'react'

import { DefaultFormProps } from './DefaultForm'
import { Fieldset, FormControl, SubmitButton } from './form'
import { Checkbox, Input } from './form-elements'
import { DisplayModes } from './GenericLeadForm'
import { Message } from './ui'

const FormFooter = styled.footer`
  padding-top: ${theme.spacing.x1}px;
`

export const WinForm = ({
  onSubmit,
  displayMode,
  labels,
  loading,
  message,
}: DefaultFormProps) => (
  <HoneypotForm
    onSubmit={onSubmit}
    render={(formState) => (
      <div>
        {displayMode === DisplayModes.form ? (
          <>
            <Fieldset>
              <FormControl field="firstName" label={labels.firstName} required>
                <Input
                  autoComplete="given-name"
                  data-testid="genericLeadForm.defaultForm.firstName"
                  field="firstName"
                  type="text"
                  validate={validateRequired()}
                  validateOnBlur
                  validateOnChange
                />
              </FormControl>
            </Fieldset>
            <Fieldset>
              <FormControl field="lastName" label={labels.lastName} required>
                <Input
                  autoComplete="family-name"
                  data-testid="genericLeadForm.defaultForm.lastName"
                  field="lastName"
                  type="text"
                  validate={validateRequired()}
                  validateOnBlur
                  validateOnChange
                />
              </FormControl>
            </Fieldset>

            <Fieldset>
              <FormControl field="email" label={labels.email} required>
                <Input
                  autoComplete="email"
                  data-testid="genericLeadForm.defaultForm.email"
                  field="email"
                  type="email"
                  validate={validateEmail()}
                  validateOnBlur
                  validateOnChange
                />
              </FormControl>
            </Fieldset>

            <Fieldset>
              <FormControl field="address.postalCode" label={labels.postcode}>
                <Input
                  autoComplete="postal-code"
                  data-testid="genericLeadForm.defaultForm.postcode"
                  field="address.postalCode"
                  type="text"
                />
              </FormControl>
            </Fieldset>
            <Fieldset>
              <FormControl
                field="address.houseNumber"
                label={labels.houseNumber}
              >
                <Input
                  autoComplete="address-line1"
                  data-testid="genericLeadForm.defaultForm.houseNumber"
                  field="address.houseNumber"
                  type="text"
                />
              </FormControl>
            </Fieldset>

            <Fieldset>
              <Checkbox
                field="privacy"
                label={labels.winPrivacy}
                data-testid="genericLeadForm.defaultForm.privacy"
              />
            </Fieldset>

            <FormFooter>
              <SubmitButton
                analyticsContext="genericLeadForm.defaultForm"
                analyticsName="submit"
                css={css`
                  width: 100%;
                  min-height: 55px;
                  font-size: ${theme.fontSizes.xLarge};
                `}
                disabled={!formState?.values?.privacy}
                loading={loading}
              >
                <Trans>Participate and Win</Trans>
              </SubmitButton>
            </FormFooter>
          </>
        ) : (
          <Message
            css={css`
              padding-top: 152px;
              padding-bottom: 152px;
            `}
            displayMode={displayMode}
            message={message}
          />
        )}
      </div>
    )}
  />
)
