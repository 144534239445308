import styled from '@emotion/styled'
import theme from '@src/theme'
import React, { ReactNode } from 'react'

import { minWidth } from '@emico/styles'

type FieldsetProps = {
  children: ReactNode
  direction?: string
}

const FS = styled.div<FieldsetProps>`
  display: flex;
  justify-content: space-between;
  margin: 0 -${theme.spacing.x1}px;

  ${({ direction = 'row' }) => `
    flex-direction: ${direction};
  `}

  @media ${minWidth('md')} {
    flex-direction: row;
  }
`

export const Fieldset = ({ children, direction }: FieldsetProps) => (
  <FS direction={direction}>{children}</FS>
)
