import styled from '@emotion/styled'
import { Input as InputComponent } from '@src/components/Input'
import React, { ComponentProps } from 'react'

const StyledInput = styled(InputComponent)`
  && {
    margin: 0;
    padding: 1px 2px;
    height: 27px;
    border: 0;
  }
`

export const Input = (props: ComponentProps<typeof StyledInput>) => (
  <StyledInput {...props} />
)
