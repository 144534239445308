import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode } from 'react'

import { CheckIcon } from '@emico/icons'
import { theme } from '@emico/styles'
import { Checkbox as ECheckbox } from '@emico/ui'
import { overrideOptional } from '@emico/utils'

interface CheckboxProps extends ComponentProps<typeof ECheckbox> {
  label?: ReactNode
  className?: string
}

const StyledHiddenCheckbox = overrideOptional(
  styled(ECheckbox)`
    position: absolute;
    opacity: 0;

    /* Make it the same size as the visual checkbox so that browser hints */
    /* show at the correct location. */
    width: 20px;
    height: 20px;
  `,
)

const StyledCheckIcon = overrideOptional(
  styled(CheckIcon)`
    color: black;
    /* IE needs sizes for svgs */
    height: 14px;
    width: 14px;
    margin-top: 2px;
  `,
)

const StyledCheck = overrideOptional(
  styled.div`
    transform: scale(0);
    transform-origin: center center;
    transition: transform 0.2s ease;
    line-height: 1;
    text-align: center;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    /* Selects:
            & this check
            * any parent (div by default, but is overridable)
            + next to a checked input */
    input[type='checkbox']:checked + * & {
      transform: scale(1);
    }

    input[type='checkbox']:not([disabled]):checked:active + * & {
      transform: scale(0.8);
    }
  `,
)

export const StyledVisualCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border: solid 1px ${theme.gray500};
  box-shadow: 0 0 3px 0 #f3efeb, 0 2px 3px 0 #f3efeb, 0 1px 2px 0 #f3efeb;
  border-radius: 4px;
  flex: 0 0 20px;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: top;

  transition: border-color 0.2s ease, background-color 0.2s ease;

  input[type='checkbox'][disabled] + & {
    opacity: 0.5;
    cursor: not-allowed;
  }

  input[type='checkbox']:checked + & {
    border-color: ${theme.gray500};
    background: white;
  }

  input[type='checkbox']:focus + & {
    border-color: #787878;
    box-shadow: 0 0 3px 0 #f3efeb, 0 2px 3px 0 #f3efeb, 0 1px 2px 0 #f3efeb;
  }

  input[type='checkbox']:not([disabled]):checked:active + & {
    background: white;
  }
`

export const StyledLabel = styled.div`
  margin-left: 10px;
`

const StyledCheckboxLabel = styled.label`
  display: flex;
  flex-flow: row nowrap;
`

const Checkbox = ({ label, className, ...props }: CheckboxProps) => (
  <StyledCheckboxLabel className={className}>
    <StyledHiddenCheckbox {...props} />
    <StyledVisualCheckbox>
      <StyledCheck>
        <StyledCheckIcon />
      </StyledCheck>
    </StyledVisualCheckbox>
    <StyledLabel>{label}</StyledLabel>
  </StyledCheckboxLabel>
)

export default overrideOptional(Checkbox)
