import styled from '@emotion/styled'
import { DisplayModes } from '@generic-lead-form/GenericLeadForm'
import theme from '@src/theme'
import React from 'react'

import { CheckIcon, CrossIcon } from '@emico/icons'

export type message = {
  title: string
  subtitle: string
}

type MessageProps = {
  displayMode: DisplayModes
  message: message
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const iconStyles = `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`

const IconWrap = styled.div<{ displayMode: DisplayModes }>`
  position: relative;
  flex-shrink: 0;
  width: 39px;
  height: 39px;
  margin-right: ${theme.spacing.x4}px;
  border-radius: 50%;

  ${({ displayMode }) =>
    (displayMode === DisplayModes.success &&
      `
      background-color: ${theme.greenColor};
    `) ||
    (displayMode === DisplayModes.fail &&
      `
      background-color: ${theme.errorColor};
    `)}
`

const SuccessIcon = styled(CheckIcon)`
  ${iconStyles}
  width: 15px;
  height: 12px;

  path {
    fill: #fff;
  }
`

const FailIcon = styled(CrossIcon)`
  ${iconStyles}
  width: 15px;
  height: 15px;

  path {
    fill: #fff;
  }
`

const Description = styled.div`
  padding-top: ${theme.spacing.x2}px;
`

const Title = styled.h3`
  margin: 0;
  font-size: ${theme.fontSizes.xxLarge};
  line-height: 1.2;
`

const Subtitle = styled.p`
  color: ${theme.gray500};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.medium};
`

export const Message = ({ displayMode, message, ...rest }: MessageProps) => (
  <Wrapper {...rest}>
    <IconWrap displayMode={displayMode}>
      {displayMode === DisplayModes.success && <SuccessIcon />}

      {displayMode === DisplayModes.fail && <FailIcon />}
    </IconWrap>

    <Description>
      <Title>{message.title}</Title>
      <Subtitle>{message.subtitle}</Subtitle>
    </Description>
  </Wrapper>
)
