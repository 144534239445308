import styled from '@emotion/styled'
import React from 'react'

import { minWidth } from '@emico/styles'
import { Image } from '@emico/ui'

type BackgroundImageProps = {
  alt: string
  height: number
  url: string
  width: number
}

const StyledImage = styled(Image)`
  display: none;

  @media ${minWidth('md')} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
`

export const BackgroundImage = (props: BackgroundImageProps) => (
  <StyledImage {...props} />
)
