import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Fieldset,
  FormControl,
  FormInner,
  SubmitButton,
} from '@generic-lead-form/form'
import { Checkbox, Input } from '@generic-lead-form/form-elements'
import { DisplayModes } from '@generic-lead-form/GenericLeadForm'
import {
  BackgroundImage,
  Message,
  message,
  Step,
  Title,
} from '@generic-lead-form/ui'
import { Trans, t } from '@lingui/macro'
import { HoneypotForm } from '@src/components/HoneypotForm'
import theme from '@src/theme'
import {
  noValidation,
  validateEmail,
  validateRequired,
} from '@src/utils/formValidation'
import { FormValues } from 'informed'
import React, { ReactElement, useState } from 'react'

import { globalWindow } from '@emico/ssr-utils'

export type WizardFormProps = {
  displayMode: DisplayModes
  labels: {
    firstName: string
    lastName: string
    email: string
    postcode: string
    houseNumber: string
    privacy: string | ReactElement
  }
  loading: boolean
  message: message
  onSubmit: (values: FormValues) => void
}

const FormHeader = styled.header`
  padding: ${theme.spacing.x1 / 2}px 0;
`

const Pointer = styled.span`
  font-family: ${theme.fontFamilySecondary};
  font-size: ${theme.fontSizes.xSmall};
  font-weight: ${theme.fontWeights.medium};
`

const FormFooter = styled.footer`
  text-align: right;
`

const buttonSize = css`
  min-width: 178px;
  min-height: 45px;
`

export const WizardForm = ({
  displayMode,
  labels,
  loading,
  message,
  onSubmit: delegatedOnSubmit,
}: WizardFormProps) => {
  const [step, setStep] = useState<number>(0)

  const onSubmit = (values: FormValues) => {
    if (step === 2) {
      delegatedOnSubmit(values)
    } else {
      setStep(step + 1)
    }
  }

  return (
    <>
      <BackgroundImage
        alt=""
        height={288}
        url={`${globalWindow?.location.origin}/media/crm-form/generic-lead-form-wizard.png`}
        width={310}
      />

      <HoneypotForm
        onSubmit={onSubmit}
        render={(formState) => (
          <FormInner>
            {displayMode === DisplayModes.form ? (
              <>
                <Step
                  index={0}
                  step={step}
                  render={(isActive) => {
                    const pointer = '1/3'

                    return (
                      <>
                        <FormHeader>
                          <Pointer>
                            {t({
                              message: 'Step {pointer}',
                              values: { pointer },
                            })}
                          </Pointer>
                          <Title>
                            <Trans>What is your email address?</Trans>
                          </Title>
                        </FormHeader>

                        <Fieldset>
                          <FormControl
                            field="email"
                            gap={theme.spacing.x2}
                            label={labels.email}
                            required
                          >
                            <Input
                              autoComplete="email"
                              data-testid="genericLeadForm.wizardForm.email"
                              field="email"
                              type="email"
                              validate={
                                isActive ? validateEmail() : noValidation()
                              }
                              validateOnBlur
                              validateOnChange
                            />
                          </FormControl>
                        </Fieldset>

                        <Fieldset>
                          <Checkbox
                            field="privacy"
                            label={labels.privacy}
                            data-testid="genericLeadForm.wizardForm.privacy"
                          />
                        </Fieldset>

                        <FormFooter>
                          <SubmitButton
                            analyticsContext="genericLeadForm.wizardForm"
                            analyticsName="goToNextStep.2"
                            css={buttonSize}
                            disabled={!formState?.values?.privacy}
                          >
                            <Trans>Next step</Trans>
                          </SubmitButton>
                        </FormFooter>
                      </>
                    )
                  }}
                />

                <Step
                  index={1}
                  step={step}
                  render={(isActive) => {
                    const pointer = '2/3'

                    return (
                      <>
                        <FormHeader>
                          <Pointer>
                            {t({
                              message: 'Step {pointer}',
                              values: { pointer },
                            })}
                          </Pointer>
                          <Title>
                            <Trans>What is your name?</Trans>
                          </Title>
                        </FormHeader>

                        <Fieldset direction="column">
                          <FormControl
                            field="firstName"
                            gap={theme.spacing.x4}
                            label={labels.firstName}
                            required
                          >
                            <Input
                              autoComplete="given-name"
                              data-testid="genericLeadForm.wizardForm.firstName"
                              field="firstName"
                              type="text"
                              validate={
                                isActive ? validateRequired() : noValidation()
                              }
                              validateOnBlur
                              validateOnChange
                            />
                          </FormControl>

                          <FormControl
                            field="lastName"
                            gap={theme.spacing.x4}
                            label={labels.lastName}
                            required
                          >
                            <Input
                              autoComplete="family-name"
                              data-testid="genericLeadForm.wizardForm.lastName"
                              field="lastName"
                              type="text"
                              validate={
                                isActive ? validateRequired() : noValidation()
                              }
                              validateOnBlur
                              validateOnChange
                            />
                          </FormControl>
                        </Fieldset>

                        <FormFooter>
                          <SubmitButton
                            analyticsContext="genericLeadForm.wizardForm"
                            analyticsName="goToNextStep.3"
                            css={buttonSize}
                          >
                            <Trans>Next step</Trans>
                          </SubmitButton>
                        </FormFooter>
                      </>
                    )
                  }}
                />

                <Step
                  index={2}
                  step={step}
                  render={() => {
                    const pointer = '3/3'

                    return (
                      <>
                        <FormHeader>
                          <Pointer>
                            {t({
                              message: 'Step {pointer}',
                              values: { pointer },
                            })}
                          </Pointer>
                          <Title>
                            <Trans>
                              What is your (residential) address? (optional)
                            </Trans>
                          </Title>
                        </FormHeader>

                        <Fieldset>
                          <FormControl
                            field="address.postalCode"
                            gap={theme.spacing.x4}
                            label={labels.postcode}
                          >
                            <Input
                              autoComplete="postal-code"
                              data-testid="genericLeadForm.wizardForm.postcode"
                              field="address.postalCode"
                              type="text"
                            />
                          </FormControl>

                          <FormControl
                            field="address.houseNumber"
                            gap={theme.spacing.x4}
                            label={labels.houseNumber}
                          >
                            <Input
                              autoComplete="address-line1"
                              data-testid="genericLeadForm.wizardForm.houseNumber"
                              field="address.houseNumber"
                              type="text"
                            />
                          </FormControl>
                        </Fieldset>

                        <FormFooter>
                          <SubmitButton
                            analyticsContext="genericLeadForm.wizardForm"
                            analyticsName="submit"
                            css={buttonSize}
                            loading={loading}
                          >
                            <Trans>Confirm</Trans>
                          </SubmitButton>
                        </FormFooter>
                      </>
                    )
                  }}
                />
              </>
            ) : (
              <Message
                css={css`
                  padding-top: 67px;
                  padding-bottom: 67px;
                `}
                displayMode={displayMode}
                message={message}
              />
            )}
          </FormInner>
        )}
      />
    </>
  )
}
