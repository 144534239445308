import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { ReactError } from '@shared/error/ReactError'
import { FormState, FormValues } from 'informed'
import React, { ReactElement } from 'react'

import { Form } from '@emico/ui'

import { Input } from './Input'

type HoneypotFormProps = {
  onSubmit: (values: FormValues) => void
  render: (formState: FormState) => ReactElement
}

const Hidden = styled.div`
  display: none;
`

export const HoneypotForm = ({
  onSubmit,
  render,
  ...props
}: HoneypotFormProps) => {
  const submit = (values: FormValues) => {
    if (values.honeypot) {
      throw new ReactError(
        t({
          id: 'honeypotForm.error',
          message: 'Honeypot field was filled with value: {honeypot}',
          values: { honeypot: values.honeypot },
        }),
      )
    }
    return onSubmit(values)
  }

  return (
    <Form onSubmit={submit} {...props}>
      {({ formState }) => (
        <>
          {render(formState)}

          <Hidden>
            <Input
              autoComplete="off"
              field="honeypot"
              type="text"
              tabIndex={-1}
              required={false}
              data-testid="honeypotForm.input"
            />
          </Hidden>
        </>
      )}
    </Form>
  )
}
