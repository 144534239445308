import styled from '@emotion/styled'
import theme from '@src/theme'
import React, { ReactNode } from 'react'

type FormTitleProps = {
  children: ReactNode
}

const FormTitle = styled.h3`
  margin: 0 0 ${theme.spacing.x2}px;
  font-family: ${theme.fontFamilySecondary};
  font-size: ${theme.fontSizes.xxLarge};
  font-weight: ${theme.fontWeights.medium};
  line-height: 1.3;
`

export const Title = ({ children }: FormTitleProps) => (
  <FormTitle>{children}</FormTitle>
)
