import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { minWidth } from '@emico/styles'

type FormInnerProps = {
  children: ReactNode
}

const Inner = styled.div`
  @media ${minWidth('md')} {
    padding-right: 363px;
  }
`

export const FormInner = ({ children }: FormInnerProps) => (
  <Inner>{children}</Inner>
)
