import { t } from '@lingui/macro'
import { FormError, FormValue, FormValues } from 'informed'

// This regex is intended to help users detect typos, not to limit user input.
// Excessive email validation hurts UX. This regex assumes people have sane email adresses without too many weird characters,...
// ...and a domain. No, we don't want users to be able to enter `root@localhost`. Yes, we will accept `a@b.c`.
const emailPattern =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/

const phoneNumberPattern = /^[0-9-+()\s]+$/

export type Validator = (value: FormValue, values: FormValues) => FormError

export const noValidation = () => () => undefined

// eslint-disable-next-line @typescript-eslint/ban-types
export const isEmpty = (value?: FormValue<{}>) =>
  value === undefined || value === null || value.toString() === ''

export const validateRequired =
  (
    message: string = t({
      id: 'formValidation.required',
      message: 'This field is required',
    }),
    // eslint-disable-next-line @typescript-eslint/ban-types
  ): Validator =>
  (value?: FormValue) =>
    isEmpty(value) ? message : undefined

export const validateEmail =
  (
    message: string = t({
      id: 'formValidation.email',
      message: 'Incorrect email address',
    }),
    // eslint-disable-next-line @typescript-eslint/ban-types
  ): Validator =>
  (value?: FormValue) =>
    !value || !emailPattern.test(value.toString()) ? message : undefined

export const validatePhone =
  (
    message: string = t({
      id: 'formValidation.phone',
      message: 'Incorrect phone number',
    }),
  ): Validator =>
  (value?: FormValue) =>
    !value ||
    value.toString().length < 4 ||
    !phoneNumberPattern.test(value.toString())
      ? message
      : undefined

export const validateNumber =
  (
    message: string = t({
      id: 'formValidation.number',
      message: 'Value must be a number',
    }),
  ): Validator =>
  (value?: FormValue) =>
    !value || isNaN(Number(value.toString())) ? message : undefined

export const validatePattern =
  (
    pattern: string,
    message: string = t({
      id: 'formValidation.pattern',
      message: 'This field is incorrectly formatted',
    }),
    flag?: string,
  ): Validator =>
  (value?: FormValue) =>
    value && !new RegExp(pattern, flag).test(String(value))
      ? message
      : undefined

export const combineValidators =
  (...args: Array<Validator | undefined>): Validator =>
  (value: FormValue, values: FormValues) =>
    args.reduce((error, validator) => {
      if (error || !validator) {
        return error
      }

      return validator(value, values)
    }, undefined)
