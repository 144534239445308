import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import { theme } from '@emico/styles'
import { Input as EInput } from '@emico/ui'

const StyledInput = styled(EInput.original)`
  border: none;
  width: 100%;
  background: none;
  font-family: ${theme.fontFamilyPrimary};
  font-weight: 500;
  font-size: 16px;
  border-radius: 0;

  &:focus {
    outline: none;
  }

  &:invalid {
    box-shadow: none;
  }
`

export const Input = (props: ComponentProps<typeof StyledInput>) => (
  <StyledInput {...props} />
)
