/**
 * Custom Error Class for React Related Errors
 *
 * This error class is used to represent and handle errors related to React.
 * It extends the built-in Error class and provides a more specific name for these errors.
 *
 * @param {string} message - A descriptive error message that explains the cause of the error.
 *
 * @example
 * throw new ReactError('Global window dataLayer is not available');
 */
export class ReactError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ReactError'
  }
}
