import styled from '@emotion/styled'
import { DefaultForm, DefaultFormProps } from '@generic-lead-form/DefaultForm'
import { WizardForm, WizardFormProps } from '@generic-lead-form/WizardForm'
import { t, Trans } from '@lingui/macro'
import { ReactError } from '@shared/error/ReactError'
import { useCreateGenericCustomerInterests } from '@src/components/useCreateGenericCustomerInterests'
import theme from '@src/theme'
import { FormValues } from 'informed'
import React, { useMemo, useState } from 'react'

import { ExternalLink } from '@emico/link'
import { globalWindow } from '@emico/ssr-utils'
import { useActiveStoreView } from '@emico/storeviews'

import { WinForm } from './WinForm'

type Address = {
  houseNumber: number
  postalCode: string
}

type FormType = 'default' | 'wizard' | 'win' | unknown

type Config = {
  campaign: string
  formType: FormType
}

type GenericFormValues = {
  address?: Address
  email: string
  firstName: string
  lastName: string
  privacy?: boolean
}

export const GENERIC_LEAD_FORM_ELEMENT_ID = 'generic-lead-form'

export enum DisplayModes {
  form = 'form',
  success = 'success',
  fail = 'fail',
}

const Wrapper = styled.div`
  position: relative;
  padding: ${theme.spacing.x3}px ${theme.spacing.x4}px ${theme.spacing.x3}px;
  background-color: ${theme.colorGreyLight};
  overflow: hidden;
`

const getConfig = (): Config | never => {
  const { NODE_ENV } = process.env

  if (NODE_ENV === 'development' || globalWindow?.Cypress) {
    const params = new URLSearchParams(globalWindow?.location.search)
    const formType = params.get('formType') || 'default'

    return {
      campaign: GENERIC_LEAD_FORM_ELEMENT_ID,
      formType, // possible options: default | wizard
    }
  } else if (NODE_ENV === 'production') {
    const containerNode: HTMLElement | null =
      globalWindow?.document.getElementById(GENERIC_LEAD_FORM_ELEMENT_ID) ??
      null

    if (containerNode instanceof HTMLElement) {
      return containerNode.dataset as Config
    }
  }

  throw new ReactError(
    t({
      message:
        'The form container was not found. Make sure you provide the correct HTML element',
    }),
  )
}

const getForm = (formType: FormType) => {
  let Component

  switch (formType) {
    case 'default':
      Component = function Form(props: DefaultFormProps) {
        return <DefaultForm {...props} />
      }
      break

    case 'wizard':
      Component = function Form(props: WizardFormProps) {
        return <WizardForm {...props} />
      }
      break
    case 'win':
      Component = function Form(props: DefaultFormProps) {
        return <WinForm {...props} />
      }
      break

    default:
      throw new ReactError(
        t({
          message: 'No lead form found for type: {formType}',
          values: { formType },
        }),
      )
  }

  return Component
}

export const GenericLeadForm = () => {
  const config = getConfig()
  const { campaign, formType } = config

  const [isLoading, setLoading] = useState<boolean>(false)
  const [displayMode, setDisplayMode] = useState<DisplayModes>(
    DisplayModes.form,
  )

  const createGenericCustomerInterests = useCreateGenericCustomerInterests()
  const storeView = useActiveStoreView()

  const labels = {
    firstName: t({
      message: 'First Name',
    }),
    lastName: t({
      message: 'Last Name',
    }),
    email: t({
      message: 'E-mail address',
    }),
    postcode: t({
      message: 'Postal Code',
    }),
    houseNumber: t({
      message: 'House Number',
    }),
    privacy: (
      <Trans>
        I agree with the
        <ExternalLink
          analyticsContext={GENERIC_LEAD_FORM_ELEMENT_ID}
          analyticsName="Link to Privacy page"
          to={t({
            message: '/cookieverklaring',
          })}
          target="_blank"
        >
          privacy statement
        </ExternalLink>
        and I give Swiss Sense permission to process my data.
      </Trans>
    ),
    winPrivacy: (
      <Trans>
        Yes, I want to participate in the giveaway and register for the Swiss
        Sense newsletter and I agree with the{' '}
        <ExternalLink
          analyticsContext={GENERIC_LEAD_FORM_ELEMENT_ID}
          analyticsName="Link to Privacy page"
          to={t({
            message: '/cookieverklaring',
          })}
          target="_blank"
        >
          privacy statement
        </ExternalLink>
        .
      </Trans>
    ),
  }
  const messages = {
    [DisplayModes.success]: {
      title: t({
        message: 'You have been successfully registered!',
      }),
      subtitle: t({
        message: 'You will now receive all updates from us by email!',
      }),
    },
    [DisplayModes.fail]: {
      title: t({
        message: 'There was a problem sending the data',
      }),
      subtitle: t({
        message: 'Please try again later',
      }),
    },
    [DisplayModes.form]: {
      title: '',
      subtitle: '',
    },
  }

  const onSubmit = async (values: FormValues) => {
    const formValues = values as GenericFormValues
    let addresses: Address[] = []
    const { address, email, firstName, lastName, privacy } = formValues

    if (address) {
      const houseNumber = Number(address?.houseNumber)

      addresses = [
        {
          houseNumber: !isNaN(houseNumber) ? houseNumber : 0,
          postalCode: address?.postalCode,
        },
      ]
    }

    setLoading(true)

    try {
      let language = storeView.language

      if (storeView.language === 'be') {
        language = 'nl'
      } else if (storeView.language === 'at') {
        language = 'de'
      }

      await createGenericCustomerInterests({
        addresses,
        email,
        firstName,
        language,
        lastName,
        source: {
          campaign,
          name: `Website ${storeView.language.toUpperCase()}`,
          type: 'website',
        },
        interestData: [
          {
            key: 'ConfigURL',
            value: globalWindow?.location.href as string,
          },
        ],
        ...(privacy
          ? {
              subscriptions: [
                {
                  subscription: 'newsletter',
                  enabled: true,
                },
              ],
            }
          : {}),
      })

      setLoading(false)
      setDisplayMode(DisplayModes.success)
    } catch (e) {
      setLoading(false)
      setDisplayMode(DisplayModes.fail)
    }
  }

  const Form = useMemo(() => getForm(formType), [formType])

  return (
    <Wrapper>
      <Form
        displayMode={displayMode}
        labels={labels}
        loading={isLoading}
        message={messages[displayMode]}
        onSubmit={onSubmit}
      />
    </Wrapper>
  )
}

export default GenericLeadForm
