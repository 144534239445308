import styled from '@emotion/styled'
import Cb, { StyledLabel, StyledVisualCheckbox } from '@src/components/Checkbox'
import theme from '@src/theme'
import React, { ReactElement } from 'react'

type CheckboxProps = {
  field: string
  label: string | ReactElement
}

const StyledCheckbox = styled(Cb)`
  position: relative;
  margin-bottom: ${theme.spacing.x2}px;
  padding: ${theme.spacing.x1}px ${theme.spacing.x1 / 2}px;

  input[type='checkbox']:checked + ${StyledVisualCheckbox} {
    border-color: ${theme.greenColor};
    background-color: ${theme.greenColor};
  }

  ${StyledVisualCheckbox} {
    border-radius: 2px;

    svg {
      color: ${theme.onPrimaryColor};
    }
  }

  ${StyledLabel} {
    margin-left: ${theme.spacing.x2}px;
    color: ${theme.colorBlack};
    font-family: ${theme.fontFamilySecondary};
    line-height: 1.5;

    a {
      font-family: inherit;
    }
  }
`

export const Checkbox = (props: CheckboxProps) => <StyledCheckbox {...props} />
